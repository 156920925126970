import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './AboutPage.css'
import Footer from '../../components/Footer/Footer';
import { aboutPageData } from '../../DATA'

const AboutPage = () => {
    const { hero, whatWeDo, missionVision, coreValues, team, workWithUs } = aboutPageData;

    return (
        <div className="about-page">
            <Navbar isFixed={true} />
            <div className="about-content">
                <h1>{hero.title}</h1>
                {hero.description.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            <div className='what-we-do'>
                <div className='text-content'>
                    <h2>{whatWeDo.title}</h2>
                    <p>{whatWeDo.description}</p>
                </div>
                <div className="image">
                    <img src={whatWeDo.imagePath1} alt="Img 1" className="Img1" />
                    <img src={whatWeDo.imagePath2} alt="Img 2" className="Img2" />
                    <img src={whatWeDo.imagePath3} alt="Img 3" className="Img3" />
                    <img src={whatWeDo.imagePath4} alt="Img 4" className="Img4" />
                </div>
            </div>
            <div className='mission-vision'>
                <h1>{missionVision.mission.title}</h1>
                <p>{missionVision.mission.description}</p>
                <h1>{missionVision.vision.title}</h1>
                <p>{missionVision.vision.description}</p>
            </div>
            <div className='core-values'>
                <h2>{coreValues.title}</h2>
                <div className="value-grid">
                    {coreValues.values.map((value, index) => (
                        <div key={index} className="value-card">
                            <h4>{value.title}</h4>
                            <p>{value.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className='meet-the-team'>
                <h2>Meet The Team</h2>
                <div className="team-members">
                    {team.map((member, index) => (
                        <div key={index} className="team-member-card">
                            <div className="member-image">
                                <img src={member.imagePath} alt={member.name} />
                            </div>
                            <div className="member-info">
                                <h3>{member.name}</h3>
                                <p>{member.position}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
            {/* <div className='work-with-us'>
                <h2>{workWithUs.title}</h2>
                <p>{workWithUs.description}</p>
                <button className="rounded-button">
                    {workWithUs.buttonText}
                    <div className="button-icon-wrapper">
                        <i className="fas fa-arrow-right button-icon"></i>
                    </div>
                </button>
            </div> */}

            <Footer isLandingPage={false} />
            
        </div>
    );
};

export default AboutPage;