import React from 'react';
import './DropdownMenu.css';
import { navbarData } from '../../DATA';

const DropdownMenu = ({ onNewsletterClick }) => {
    return (
        <div className="dropdown-menu">
            {navbarData.dropdownLinks.map((link, index) => (
                <a
                    key={index}
                    href={link.url}
                    onClick={link.text.toLowerCase() === 'newsletter' ? onNewsletterClick : undefined}
                >
                    {link.text}
                </a>
            ))}
        </div>
    );
};

export default DropdownMenu;