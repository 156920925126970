import React, { useState } from 'react';
import './Footer.css';
import { footerData } from '../../DATA';
import NewsletterPopup from '../PopUps/NewsletterPopup';
import GetInTouchPopup from '../PopUps/GetInTouchPopup';
import { FaXTwitter } from 'react-icons/fa6';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = ({ isLandingPage }) => {
    const { logo, columns, newsletterSignup, socialLinks, backToTop, bottomLinks } = footerData;
    const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
    const [isGetInTouchOpen, setIsGetInTouchOpen] = useState(false);

    const handleNewsletterClick = (event) => {
        event.preventDefault();
        setIsNewsletterOpen(true);
    };

    const handleGetInTouchClick = (event) => {
        event.preventDefault();
        setIsGetInTouchOpen(true);
    };

    const handleBackToTopClick = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <footer className={`footer ${isLandingPage ? 'landing-footer' : 'default-footer'}`}>
                <div className="footer-content">
                    <a href="/" className="footer-logo">{logo}</a>
                    <div className="footer-links">
                        {columns.map((column, index) => (
                            <div key={index} className="footer-column">
                                {column.links.map((link, linkIndex) => (
                                    link.text === "Newsletter" ? (
                                        <a key={linkIndex} href="#" onClick={handleNewsletterClick}>
                                            {link.text}
                                        </a>
                                    ) : link.text === "Contact Us" ? (
                                        <a key={linkIndex} href="#" onClick={handleGetInTouchClick}>
                                            {link.text}
                                        </a>
                                    ) : (
                                        <a key={linkIndex} href={link.url}>{link.text}</a>
                                    )
                                ))}
                            </div>
                        ))}
                    </div>
                    {/* <div className="newsletter-signup">
                        <p>{newsletterSignup.text}</p>
                        <form className="email-input">
                            <input type="email" placeholder={newsletterSignup.placeholder} required />
                            <button type="submit" aria-label="Submit email">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </form>
                    </div> */}
                    <div className="footer-container">
                        <div className="social-links">
                            <p>Follow Our Social Networks</p>
                            <div className="social-icons">
                                <a href="/" aria-label="Facebook"><FaFacebook size={24} /></a>
                                <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter"><FaXTwitter size={24} /></a>
                                <a href="#" aria-label="Instagram"><FaInstagram size={24} /></a>
                                <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn"><FaLinkedin size={24} /></a>
                            </div>
                        </div>
                        <div className="back-to-top">
                            <a href="#top" onClick={handleBackToTopClick}>
                                {backToTop}
                                <button type="submit" aria-label="Submit email">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <p>© {logo} 2024</p>
                        {bottomLinks.map((link, index) => (
                            <a key={index} href={link.url}>{link.text}</a>
                        ))}
                    </div>
                </div>
            </footer>
            <NewsletterPopup isOpen={isNewsletterOpen} onClose={() => setIsNewsletterOpen(false)} />
            <GetInTouchPopup isOpen={isGetInTouchOpen} onClose={() => setIsGetInTouchOpen(false)} /> {/* Add GetInTouchPopup component */}
        </>
    );
};

export default Footer;