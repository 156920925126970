import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './PodcastPage.css'
import Footer from '../../components/Footer/Footer';
import { podcastPageData } from '../../DATA';

const PodcastPage = () => {
    const [showAllEpisodes, setShowAllEpisodes] = useState(false);
    const [showAllGuests, setShowAllGuests] = useState(false);
    const [formData, setFormData] = useState({ Name: '', email: '', message: '' });
    const [email, setEmail] = useState('');

    const { hero, empoweringVoice, categories, episodes, guests } = podcastPageData;

    const displayedEpisodes = showAllEpisodes ? episodes : episodes.slice(0, 3);
    const displayedGuests = showAllGuests ? guests : guests.slice(0, 4);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        setFormData({ Name: '', email: '', message: '' });
    };

    const NewsletterHandleSubmit = (e) => {
        e.preventDefault();
        console.log('Subscribed with email:', email);
        setEmail('');
    };

    return (
        <div className="podcast-page">
            <Navbar isFixed={true} />
            <section className="podcast-hero">
                <div className="hero-content">
                    <div className="hero-image">
                        <img src={hero.imagePath} alt="heroImg" />
                    </div>
                    <div className="hero-text">
                        <h1>{hero.title}</h1>
                        <p>{hero.description}</p>
                        {/* <form className="subscribe-form">
                            <input type="email" placeholder="Your Email" />
                            <button type="submit">Subscribe</button>
                        </form> */}
                    </div>
                </div>
            </section>
            <section className="empowering-voice">
                <div className="empowering-content">
                    <div className="empowering-text">
                        <h2>{empoweringVoice.title}</h2>
                        <p>{empoweringVoice.description}</p>
                    </div>
                    <div className="empowering-image">
                        <img src={empoweringVoice.imagePath} alt="replaceImg" />
                    </div>
                </div>
            </section>
            {/* <section className="top-categories">
                <div className="categories-content">
                    <h2>TOP CATEGORIES</h2>
                    <div className="categories-list">
                        {categories.map((category, index) => (
                            <div key={index} className="category-item">
                                <img src={category.imagePath} alt="Category Icon" className="category-icon" />
                                <span>{category.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            <section className="all-episodes">
                <div className="episodes-content">
                    <h2>ALL EPISODES</h2>
                    <div className="episodes-grid">
                        {displayedEpisodes.map((episode, index) => (
                            <div key={index} className="episode-item">
                                <img src={episode.imagePath} alt="Episode" />
                                <h3>{episode.title}</h3>
                                <p>{episode.host}</p>
                            </div>
                        ))}
                    </div>
                    {!showAllEpisodes && episodes.length > 3 && (
                        <button className="view-all-btn" onClick={() => setShowAllEpisodes(true)}>
                            View All
                        </button>
                    )}
                </div>
            </section>
            <section className="our-guests">
                <div className="guests-content">
                    <h2>OUR GUESTS</h2>
                    <div className="guests-grid">
                        {displayedGuests.map((guest, index) => (
                            <div key={index} className="guest-item">
                                <img src={guest.imagePath} alt="Guest" className="guest-image" />
                                <h3>{guest.name}</h3>
                                <p>{guest.podcast}</p>
                            </div>
                        ))}
                    </div>
                    {!showAllGuests && guests.length > 4 && (
                        <button className="view-all-btn" onClick={() => setShowAllGuests(true)}>
                            View All
                        </button>
                    )}
                </div>
            </section>
            {/* <section className="contact-us">
                <h2>CONTACT US</h2>
                <div className="contact-content">
                    <div className="contact-image">
                        <img src={podcastPageData.contact.imagePath} alt="Contact" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input type="text" name="Name" placeholder="Name" value={formData.Name} onChange={handleChange} required />
                        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                        <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </section>
            <section className="newsletter">
                <div className="newsletter-content">
                    <h2>Subscribe to our newsletter</h2>
                    <p>Want to be the first to know about new episodes and exclusive content? Sign up for our newsletter and never miss an update!</p>
                    <form onSubmit={NewsletterHandleSubmit}>
                        <div className='newsletter-input'>
                            <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <button type="submit">Subscribe</button>
                        </div>
                    </form>
                </div>
            </section> */}
            <Footer isLandingPage={false} />
        </div>
    );
};

export default PodcastPage;