import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="terms-of-service-page">
            <Navbar isFixed={true}/>
            <div className="terms-container">
                <h1>Terms of Service</h1>
                <p className="last-updated">Last updated: 07/09/2024</p>
                <p>Welcome to YUKTII VENTURES!</p>
                <p>Thank you for using our platform and trusting us with your career goals. These Terms of Service ("Terms") govern your access to and use of YUKTII VENTURE's website and platform ("Services"), provided by YUKTII VENTURE ("us", "we", or "our").</p>
                <p>By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Services.</p>

                <h2>1. Eligibility</h2>
                <p>You must be at least 13 years old to use our Services. By agreeing to these Terms, you represent and warrant that you are at least 13 years old.</p>

                <h2>2. Accounts</h2>
                <p>When you create an account on YUKTII VENTURE, you are responsible for:</p>
                <ul>
                    <li>Maintaining the security of your account and password.</li>
                    <li>All activities that occur under your account.</li>
                </ul>
                <p>Use a "strong" password to ensure the safety of your private information. We are not liable for any loss or damage resulting from your failure to comply with these security requirements.</p>

                <h2>3. Content</h2>
                <p><strong>Your Responsibility:</strong> You are solely responsible for the content you create, upload, post, or otherwise transmit using our Services.</p>
                <p><strong>Our Rights:</strong> We may review and remove content that violates our policies, but we are not obligated to do so. Do not assume your content will be reviewed.</p>
                <p><strong>Prohibited Content:</strong> You agree not to post or transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or otherwise objectionable.</p>

                <h2>4. Termination</h2>
                <p>We may terminate your account at any time for any reason, including but not limited to:</p>
                <ul>
                    <li>Violation of these Terms.</li>
                    <li>Inactivity.</li>
                    <li>Suspicious activity.</li>
                </ul>
                <p>Such termination will result in deactivation or deletion of your account and loss of content. No refunds are issued in such cases.</p>

                <h2>5. Privacy</h2>
                <p>Your personal information and content are governed by our Privacy Policy. By using YUKTII VENTURE, you agree to our Privacy Policy.</p>

                <h2>6. Limited Warranties and Liability</h2>
                <p>YUKTII VENTURE provides the Services "AS IS" without warranty of any kind. We are not responsible for any damages resulting from the loss of use, data, or profits in connection with the Services.</p>

                <h2>7. Governing Law</h2>
                <p>These Terms are governed by the laws of EU/CH, without regard to conflict of law provisions. Any disputes arising from these Terms will be resolved in the courts of EU/CH.</p>

                <h2>8. Changes</h2>
                <p>We may update these Terms at any time. If a change is material, we will provide at least 15 days notice before the new terms take effect. Your continued use of the Services constitutes acceptance of the new terms.</p>
            </div>
            <Footer />
        </div>
    );
};

export default TermsOfService;
