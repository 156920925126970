import React, { useState } from 'react';
import './PopupStyles.css'

const GetInTouchPopup = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        reason: '',
        message: '',
        hearAboutUs: '',
        subscribe: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted:', formData);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup get-in-touch-popup">
                <button className="close-btn" onClick={onClose}>X</button>
                <h2>Get in touch</h2>
                <p>Any question or remarks? Just write us a message!</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="fullName"
                        placeholder="Full Name"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {/* <input
                        type="text"
                        name="reason"
                        placeholder="Reason for contact"
                        value={formData.reason}
                        onChange={handleChange}
                        required
                    /> */}
                    <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    {/* <input
                        type="text"
                        name="hearAboutUs"
                        placeholder="How did you hear about us?"
                        value={formData.hearAboutUs}
                        onChange={handleChange}
                    /> */}
                    <label className="subscribe-label">
                        <input
                            type="checkbox"
                            name="subscribe"
                            checked={formData.subscribe}
                            onChange={handleChange}
                        />
                        Subscribe to our newsletter
                    </label>
                    <button type="submit" className="submit-btn">Send message</button>
                </form>
            </div>
        </div>
    );
};

export default GetInTouchPopup;