import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { writingsPageData } from '../../DATA'
import "./WritingPage.css";

const WritingsPage = () => {
    const [visibleCount, setVisibleCount] = useState(3);
    const { pageTitle, pageDescription, buttonCategories, writings, contributeSection } = writingsPageData;

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 3);
    };

    return (
        <div className="page">
            <Navbar isFixed={true} />
            <main className="writings-page">
                <div className="writings-content">
                    <h1>{pageTitle}</h1>
                    <p>{pageDescription}</p>

                    <div className="writings-buttons">
                        {buttonCategories.map((category, index) => (
                            <button key={index}>{category}</button>
                        ))}
                    </div>
                </div>

                <div className="writings-list">
                    {writings.slice(0, visibleCount).map((writing, index) => (
                        <a href={writing.link} target="_blank" rel="noopener noreferrer" key={index} className="writing-card">
                            <div>
                                <h2>{writing.title}</h2>
                                <p>{writing.content}</p>
                                <div className="writing-info">
                                    <span>{writing.source}</span>
                                    <span>{writing.date}</span>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                {visibleCount < writings.length && (
                    <div className="more-button-container">
                        <button className="more-button" onClick={handleShowMore}>
                            More
                        </button>
                    </div>
                )}
            </main>

            <div className="contribute-section">
                <p>{contributeSection.description}</p>
                <button className="contribute-button">{contributeSection.buttonText}</button>
            </div>
            <Footer isLandingPage={false} />
        </div>
    );
};

export default WritingsPage;
