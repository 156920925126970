import React, { useState, useEffect } from 'react';
import './Navbar.css';
import DropdownMenu from './DropdownMenu';
import NewsletterPopup from '../PopUps/NewsletterPopup';
import GetInTouchPopup from '../PopUps/GetInTouchPopup';
import { navbarData } from '../../DATA'

const Navbar = ({ isFixed = false }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
    const [isGetInTouchOpen, setIsGetInTouchOpen] = useState(false);

    useEffect(() => {
        if (!isFixed) return;

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFixed]);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleNewsletterClick = (e) => {
        e.preventDefault();
        setIsNewsletterOpen(true);
    };

    const handleGetInTouchClick = (e) => {
        e.preventDefault();
        setIsGetInTouchOpen(true);
    };

    const renderLogo = () => {
        const [firstWord, secondWord] = navbarData.logo.split(' ');
        return (
            <>
                <div className="logo-text">
                    {firstWord.split('').map((char, index) =>
                        char.toUpperCase() === 'U' ?
                            <span key={index} className="orange-u">{char}</span> :
                            char
                    )}
                </div>
                <div className="logo-subtext">{secondWord}</div>
            </>
        );
    };

    return (
        <>
            <nav className={`navbar ${isFixed && isScrolled ? 'navbar-scrolled' : ''} ${isFixed ? 'navbar-fixed' : ''}`}>
                <a href="/" className="navbar-logo">
                    {renderLogo()}
                </a>

                <div className={`navbar-links ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
                    {navbarData.links.map((link, index) => (
                        <a key={index} href={link.url}>{link.text}</a>
                    ))}

                    <div className="dropdown desktop-only">
                        <button className="dropdown-toggle" onClick={handleDropdownToggle}>
                            More <span className="dropdown-arrow">&#9662;</span>
                        </button>
                        {isDropdownOpen && <DropdownMenu onNewsletterClick={handleNewsletterClick} />}
                    </div>
                    <div className="mobile-only">
                        <p className="more-header">More</p>
                        <DropdownMenu onNewsletterClick={handleNewsletterClick} />
                    </div>
                    {/* <a href={navbarData.contactLink.url} className="get-in-touch" onClick={handleGetInTouchClick}>{navbarData.contactLink.text}</a> */}
                </div>
                <button className="mobile-menu-toggle" onClick={handleMobileMenuToggle}>
                    ☰
                </button>
            </nav>
            <NewsletterPopup isOpen={isNewsletterOpen} onClose={() => setIsNewsletterOpen(false)} />
            <GetInTouchPopup isOpen={isGetInTouchOpen} onClose={() => setIsGetInTouchOpen(false)} />
        </>
    );
};

export default Navbar;