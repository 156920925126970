import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './PrivacyPolicy.css'; 

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar isFixed={true}/> 
            <div className="privacy-policy-page">
                <div className="privacy-container">
                    <h1>Privacy Policy</h1>
                    <p className="last-updated">Last updated: 07/09/2024</p>

                    <h2>Introduction</h2>
                    <p>
                        At YUKTII VENTURES, we value your privacy. This Privacy Policy outlines how we collect, use, and protect your information. By agreeing to our Terms of Service, you verify that you are at least 13 years old and agree to the following conditions:
                    </p>
                    <ul>
                        <li>(a) I authorize the processing of personal data contained within my CV, according to GDPR (EU) 2016/679, Article 6.1(a).</li>
                        <li>(b) I hereby consent to the processing of this CV and the personal data contained within, by anyone who receives this document, in accordance with Article 6.1(a) of GDPR (EU) 2016/679.</li>
                    </ul>

                    <h2>Information We Collect</h2>
                    <ul>
                        <li>Usage Information: Pages visited, features used, search queries, time spent on each page.</li>
                        <li>Log Information: Your IP address, timestamps of access, browser type and version, operating system.</li>
                        <li>Account Information: Username, email address, password.</li>
                        <li>Contact Information: When you contact us through our website, subscribe, or participate in surveys or contests.</li>
                    </ul>

                    <h2>How We Use Information</h2>
                    <ul>
                        <li>Usage Information: To analyze platform usage, improve functionality, and develop new features.</li>
                        <li>Log Information: To monitor technical issues, prevent fraud, and ensure platform security.</li>
                        <li>Account Information: To create and manage your account, communicate with you, and provide platform access.</li>
                        <li>Contact Information: To respond to inquiries, send newsletters, and provide support.</li>
                    </ul>

                    <h2>Data Retention</h2>
                    <p>
                        Usage Information is stored for up to 26 months, and other information like log and account details is retained as necessary. Resume content is stored as long as it remains on the platform.
                    </p>

                    <h2>Cookies</h2>
                    <p>We use cookies to enhance your experience. You can disable cookies through your browser settings.</p>

                    <h2>Your Legal Rights</h2>
                    <p>You have the right to access, correct, delete, or restrict your personal data. Contact us at RIG-Intern@proton.me to exercise these rights.</p>

                    <h2>Data Deletion</h2>
                    <p>Request data deletion by emailing RIG-Intern@proton.me. We will delete your information within 30 days.</p>

                    <h2>Data Storage</h2>
                    <p>
                        We use Azure Free Tier cloud storage. Information is shared with analytics services like Google Analytics, Amplitude, etc., in compliance with the EU-US Privacy Shield.
                    </p>

                    <h2>Changes to Privacy Policy</h2>
                    <p>We may update this Privacy Policy periodically. Any changes will be notified via email and our website.</p>

                    <p>For questions or concerns, contact us at RIG-Intern@proton.me.</p>
                </div>
            </div>
            <Footer /> 
        </>
    );
};

export default PrivacyPolicy;
