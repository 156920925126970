import React,{ useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import './VenturesPage.css';
import Footer from '../../components/Footer/Footer';
import { venturesPageData } from '../../DATA'
import GetInTouchPopup from '../../components/PopUps/GetInTouchPopup';

const VenturesPage = () => {
    const { title, description, companies, vision, exploreSection } = venturesPageData;

    const [isGetInTouchOpen, setIsGetInTouchOpen] = useState(false);

    const handleGetInTouchClick = (e) => {
        e.preventDefault();
        setIsGetInTouchOpen(true);
    };

    return (
        <div className="ventures-page">
            <Navbar isFixed={true} />
            <main className="ventures-content">
                <h1 className="ventures-title">{title}</h1>
                <p className="ventures-description">{description}</p>
                <section className="majority-owned">
                    <div className="company-grid">
                        {companies.map((company, index) => (
                            <Link
                                key={index}
                                to={company.url} 
                                className="company-card"
                                style={{
                                    backgroundImage: `url(${company.imagePath})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            >
                                <h3 className="company-name">{company.name}</h3>
                            </Link>
                        ))}
                    </div>
                </section>
            </main>
            <section className="vision-section">
                <h2 className="vision-title">{vision.title}</h2>
                <p className="vision-description">{vision.description}</p>
            </section>

            <section className="explore-section">
                <h2 className="explore-title">{exploreSection.title}</h2>
                <ul className="ventures-list">
                <li>{exploreSection.l0}</li>
                    {/* <li>{exploreSection.l1}</li>
                    <li>{exploreSection.l2}</li>
                    <li>{exploreSection.l3}</li> */}
                </ul>
                {/* <button className="explore-button" onClick={handleGetInTouchClick}>{exploreSection.buttonText}</button>
                <GetInTouchPopup isOpen={isGetInTouchOpen} onClose={() => setIsGetInTouchOpen(false)} /> */}
            </section>

            <Footer isLandingPage={false} />
        </div>
    );
};

export default VenturesPage;