import React, { useState } from 'react';
import './PopupStyles.css'

const NewsletterPopup = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle newsletter subscription logic here
        console.log('Subscribed with email:', email);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup newsletter-popup">
                <button className="close-btn" onClick={onClose}>X</button>
                <h2>Want to keep up to date on what we're up to?</h2>
                <p>Receive new articles and resources directly in your inbox. Fill your email below to join our email newsletter today.</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Enter your email address..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit" className="submit-btn">Subscribe</button>
                </form>
            </div>
        </div>
    );
};

export default NewsletterPopup;